<template>
  <li
    ref="header"
    class="dropdown-item full-height"
    :class="{ open: opened }"
    :aria-haspopup="!!$slots.content"
    :aria-expanded="$slots.content ? opened : null"
  >
    <i
      v-if="prefixIcon"
      :class="prefixIcon"
      data-testid="icon"
      class="margin-right-xxs"
    />
    <div class="dropdown-item__title">
      <slot v-bind="{ opened, active, open, close, toggle }" />
    </div>
    <template v-if="withChevron && $slots.content">
      <button
        class="link-chevron-medium-down margin-left-xxs"
        :class="{ opened }"
        :aria-expanded="opened"
        @click="toggle"
      >
        <span class="visibility-hidden">Open store information dropdown</span>
      </button>
    </template>
    <menu
      v-if="$slots.content"
      ref="content"
      class="dropdown-item__content"
      :class="{'dropdown-item__content--left': positionX === 'left'}"
      :style="{
        minWidth,
      }"
    >
      <slot
        name="content"
        :opened="opened"
      />
    </menu>
  </li>
</template>

<script setup>
import { ref, watch } from 'vue';

import { useOverlay } from '../../composables/ui/useOverlay';
import { useDropdown } from '../../composables/accessibility/useDropdown';
import useFeatureEligibility from '../../composables/useFeatureEligibility';
import { useMobileNav } from '../../composables/useMobileNav';

defineProps({ // eslint-disable-line no-undef
  prefixIcon: {
    type: String,
    default: null,
  },
  withChevron: {
    type: Boolean,
    default: false,
  },
  minWidth: {
    type: String,
    default: null,
  },
  positionX: {
    type: String,
    default: 'right',
  },
});

// Refs
const header = ref(null);
const content = ref(null);

// Composables
const overlay = useOverlay('LINK_RAIL_OVERLAY');
const {
  opened, open, close, toggle, active,
} = useDropdown({ header, content });
const { closeNav, isMenuOpened } = useMobileNav();
const { enhancedDesktopNavFeature } = useFeatureEligibility();

// When the dropdown opens, show the overlay, and when it closes, hide the overlay
watch(opened, (value) => {
  if (value) {
    if (enhancedDesktopNavFeature.value && isMenuOpened.value) closeNav();
    overlay.show();
    return;
  }
  overlay.hide();
});
</script>

<style lang="scss" scoped brand="mcom">
@import "../../scss/icons.mcom.scss";
.link-chevron-medium-down {
  @extend .chevron-medium-down-black;
}
.dropdown-item__content {
  border-radius: 0 0 8px 8px;
}
</style>

<style lang="scss" scoped brand="bcom">
@import "../../scss/icons.bcom.scss";

.link-chevron-medium-down {
  @extend .chevron-small-down;
}
</style>

<style lang="scss" scoped>
.dropdown-item {
  position: relative;
  display: flex;
  align-items: center;

  .dropdown-item__title {
    position: relative;
  }

  &.open:hover, &.open:focus-within {
    .dropdown-item__content {
      display: block;
    }
  }
}

ul .dropdown-item:last-child {
  .dropdown-item__content {
    right: 0;
  }
}

.dropdown-item__content {
  position: absolute;
  top: calc(100% + 1px);
  right: calc(-1 * #{map-get($grid-margin-gutters, 'medium')});;
  background-color: $white;
  overflow: hidden;
  display: none;

  list-style: none;
  padding: 0;
  margin: 0;

  &--left {
    left: -20px;
    right: auto;
  }
}

.link-chevron-medium-down {
  width: 16px;
  height: 16px;
  background-size: 16px 16px;

  &.opened {
    transform: rotate(-180deg);
  }
}

.profile {
  width: 16px;
  height: 16px;
  background-size: 16px;
}

</style>
