const { viewTypes } = require('../constants');
const { logError } = require('./Logger');

const ENABLE_FALLBACK = true;

function buildMockFileName({
  isMobile, isInternational, isDesktopOnly, experimentId,
}) {
  const deviceType = isDesktopOnly || !isMobile ? viewTypes.desktop : viewTypes.mobile;
  const siteType = isInternational ? 'international' : 'domestic'; // eslint-disable-line no-nested-ternary
  const experiment = experimentId ? `.${experimentId}` : '';

  return `${deviceType}.${siteType}${experiment}`;
}

async function getHeaderFallback(params) {
  if (!ENABLE_FALLBACK) {
    return null;
  }

  const context = {
    ...params,
    isDesktopOnly: false,
  };

  const fileName = buildMockFileName(context);
  // eslint-disable-next-line
  const value = await require(/* webpackChunkName: "headerMock" */ `../../mocks/fallback/header/${fileName}.js`);

  logError(`Header xapi call failed, using mock "${fileName}" instead.`);

  return { value };
}

async function getFooterFallback(params) {
  if (!ENABLE_FALLBACK) {
    return null;
  }

  const context = {
    ...params,
    isDesktopOnly: true,
  };

  const fileName = buildMockFileName(context);
  // eslint-disable-next-line
  const value = await require(/* webpackChunkName: "footerMock" */ `../../mocks/fallback/footer/${fileName}.js`);

  logError(`Footer xapi call failed, using mock "${fileName}" instead.`);
  return { value };
}

module.exports = {
  buildMockFileName,
  getHeaderFallback,
  getFooterFallback,
};
