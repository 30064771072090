<template>
  <div
    v-if="shouldShownLastSearches"
    class="search-history"
  >
    <div class="grid-x align-middle align-justify margin-bottom-s large-margin-bottom-s">
      <h3>
        Recent Searches
      </h3>
      <button
        type="button"
        @click="clearHistoryAndFocus"
      >
        <span class="link-sm">Clear All</span>
      </button>
    </div>

    <ul
      ref="listEl"
      class="grid-y"
    >
      <li
        v-for="(item, index) in searchHistory"
        :key="item"
        role="option"
        @click="emits('select', { item: item, index: index })"
      >
        <Link
          class="undecorated-link text-left"
          :href="buildUrl(item)"
          @keydown.arrow-up.prevent="onKeydownArrowUp"
          @keydown.arrow-down.prevent="onKeydownArrowDown"
        >
          {{ item }}
        </Link>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useMounted } from '@vueuse/core';

import useSearchHistory from '../../composables/dumb/useSearchHistory';
import Link from '../common/Link';
import { GET_IS_HAVE_SUGGESTIONS } from '../../types/getters';
import getSearchPrefix from '../../utils/getSearchPrefix';
import { useSearchResultList } from '../../composables/accessibility/useSearchResultList';
import { normalizeSearchString } from '../../utils/normalizeSearchString';
import { SEARCH_PAGE_PREFIXES } from '../../constants';
import addQueryParam from '../../utils/addQueryParam';

const emits = defineEmits(['select', 'cleared']); // eslint-disable-line no-undef

const store = useStore();
const { isMcom } = store.state.envProps;
const listEl = ref(null);
const mounted = useMounted();

const { onKeydownArrowUp, onKeydownArrowDown } = useSearchResultList({ listEl });

const { searchHistory, clearSearchHistory } = useSearchHistory();
const hasSuggestions = computed(() => store.getters[`navSearchSuggestionsStore/${GET_IS_HAVE_SUGGESTIONS}`]);
const shouldShownLastSearches = computed(() => mounted.value && Boolean(searchHistory.value.length) && !hasSuggestions.value);
const urlPrefix = getSearchPrefix(store);
const buildUrl = (label) => {
  const normalizedLabel = normalizeSearchString(label, urlPrefix === SEARCH_PAGE_PREFIXES.featured);
  const normalizedSearchValue = normalizeSearchString(label, false);

  return addQueryParam(`${urlPrefix}${normalizedLabel}`, {
    cm_kws_ls: isMcom ? normalizedSearchValue : '',
    ss: true,
  });
};

function clearHistoryAndFocus() {
  clearSearchHistory();
  emits('cleared');
}

defineExpose({ listEl }); // eslint-disable-line no-undef
</script>

<style lang="scss" scoped>
@import '../../scss/mixins/index.scss';

button {
  color: $gray-1-color;
  align-self: flex-end;
}

a {
  color: $gray-1-color;
  display: block;
  &:hover,
  &:focus {
    color: $highlight-color;
  }
}

.search-history {
  margin-bottom: .5px;
  padding-right: 20px;
  @include margin-top-s;
}

@include breakpoint(large up) {
  .search-history {
    padding-right: 0;
    @include margin-top-m;
  }
}
</style>

<style lang="scss" scoped brand="mcom">
@import '../../scss/mixins.mcom.scss';
h3 {
  @include body(large);
}

ul {
  gap: map-get($grid-margin-gutters, 'medium');
}

@include breakpoint(large up) {
  .search-history {
    @include margin-left-l;
    @include margin-right-l;
  }
}
</style>

<style lang="scss" scoped brand="bcom">
@import '../../scss/mixins.bcom.scss';
h3 {
  @include header(h4);
}

ul {
  gap: map-get($grid-margin-gutters, 'large');
}

@include breakpoint(large up) {
  ul {
    gap: map-get($grid-margin-gutters, 'medium');
  }
}

@include breakpoint(large up) {
  .search-history {
    @include margin-left-m;
    @include margin-right-m;
  }
}
</style>
