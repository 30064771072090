import { computed } from 'vue';

import { dataSources, viewTypes, viewTypeToDeviceType } from '../constants';
import { useHeaderFetch } from './dumb/useNavigationFetch';
import { SET_FETCH_ERROR, SET_HEADER_DATA } from '../types/mutations';
import useExperimentsIds from './useExperimentsIds';
import { loadHeaderMock, normalizeHeaderData, getExperimentIdForMock } from '../utils/headerHelpers';
import useExperiments from './useExperiments';
import { GET_HEADER_DATA, GET_MYACCOUNT_LINKS } from '../types/getters';
import BcomFobTitle from '../constants/bcom/MobileFobTitle';
import { useMobileNav } from './useMobileNav';
import experimentationConfig from '../features/experimentation/config';
import { NAV_KILLSWITCH_STORE } from '../types/names';

// passing _store is needed for tests
export function useHeaderMenuData(store) {
  const { isMcom } = store.state.envProps;
  const experiments = useExperiments();
  const experimentIds = useExperimentsIds(store);
  const { stack, lastStackItem } = useMobileNav();

  const viewType = computed(() => store.state.navViewTypeStore.viewType);
  const username = computed(() => store.state.navUser.data.username);
  const isSignedIn = computed(() => store.state.navUser.data.isSignedIn);

  function getDataSource(forceViewType) {
    const _viewType = forceViewType || viewType.value;

    if (_viewType === viewTypes.desktop) {
      return dataSources.aem;
    }

    return dataSources.aemWithMenuFromStella;
  }

  function getMenuData() {
    return store.state.headerStore.data[viewType.value]?.menu || {};
  }

  function getFlyouts() {
    return store.state.headerStore.data[viewType.value]?.desktopMenu || [];
  }

  function getCategoryListFromMenuByIds(menu, ids) {
    return ids.reduce((acc, id) => {
      const category = menu[id];

      if (category) {
        acc.push(category);
      }

      return acc;
    }, []);
  }

  function getBcomTopLevelFromMenuByIds(menu) {
    return Object.values(menu)
      .reduce((acc, category) => {
        if (!category.parentId) {
          acc.push(category);
        }
        return acc;
      }, [])
      .sort((a, b) => {
        if ('order' in a && 'order' in b) {
          return a.order - b.order;
        }
        return 0;
      });
  }

  function getGroupsTopCategories() {
    const menu = getMenuData();
    const topGroupsMenu = store.state.headerStore.data[viewType.value]?.meta?.properties.topGroupsMenu || [];

    return topGroupsMenu.reduce((acc, cur) => {
      const { title, ids } = cur;
      const data = getCategoryListFromMenuByIds(menu, ids);

      if (!data.length) return acc;

      const group = { title, data };

      return acc.concat(group);
    }, []);
  }

  function getIsAemMenu(forceViewType) {
    return getDataSource(forceViewType) === dataSources.aem;
  }

  function getMobileTopCategories() {
    const menu = getMenuData();

    if (isMcom) {
      const topMenuIds = store.state.headerStore.data[viewType.value]?.meta?.properties?.topMenu?.split(',') || [];

      return getCategoryListFromMenuByIds(menu, topMenuIds);
    }

    return getBcomTopLevelFromMenuByIds(menu);
  }

  async function processFetchedData(result, isFetchSucceed, forceViewType, experimentIdsForRequest = [], doNotUseMock = false) {
    const rootState = store.state;
    const { isInternational } = rootState.pageData.navigation.context;
    const _viewType = forceViewType || viewType.value;
    const isMobile = _viewType === viewTypes.mobile;
    let data;

    if (isFetchSucceed) {
      data = result;
      store.commit(`headerStore/${SET_FETCH_ERROR}`, false);
    } else if (doNotUseMock) {
      return;
    } else {
      const experimentId = getExperimentIdForMock({
        experimentIds: experimentIdsForRequest,
        viewType: _viewType,
        isInternational,
      });
      data = await loadHeaderMock({
        isInternational,
        isMcom,
        isMobile,
        experimentId,
      });
      store.commit(`headerStore/${SET_FETCH_ERROR}`, true);
      rootState.envProps.isNavMockHeader = true;
    }

    const isAemMenu = getIsAemMenu(_viewType);
    const { data: transformedData, filteredKillswitches } = normalizeHeaderData({
      source: data,
      viewType: _viewType,
      experimentIds: experimentIdsForRequest,
      isDomestic: rootState.pageData.navigation.context.isDomestic,
      killswitchesOverride: rootState.pageData.killswitches?.override,
      isMcom,
      isAemMenu,
    });

    store.dispatch('navKillswitchStore/applyKillswitches', filteredKillswitches);
    store.commit(`headerStore/${SET_HEADER_DATA}`, { viewType: viewType.value, data: transformedData });
  }

  async function _fetch(force, forceViewType = null, additionalExperiments = [], doNotUseMock = false) {
    const _viewType = forceViewType || viewType.value;

    if (!force && (
      (_viewType === viewTypes.mobile && Object.keys(getMenuData()).length)
      || (_viewType === viewTypes.desktop && getFlyouts().length)
    )) {
      return;
    }

    const rootState = store.state;
    const { context, queryParams: serverQueryParams = {} } = rootState.pageData.navigation;
    const { navMock: isMockMode } = serverQueryParams;
    const experimentIdsForRequest = [...experimentIds.value, ...additionalExperiments];

    if (isMockMode) {
      await processFetchedData(null, false, _viewType, [], doNotUseMock);
    } else {
      const { hostlink: clientBaseUrl } = rootState.envProps;
      const { regionCode, countryCode } = context;
      const deviceType = viewTypeToDeviceType[_viewType];
      const { fetch: headerFetch, result } = useHeaderFetch({
        experimentIds: experimentIdsForRequest,
        overrideCMParams: _viewType !== viewType.value,
        regionCode,
        countryCode,
        deviceType,
        clientBaseUrl,
        isMcom,
      });

      await headerFetch();
      const isFetchSucceed = result.value && typeof result.value === 'object';

      await processFetchedData(result.value, isFetchSucceed, _viewType, experimentIdsForRequest, doNotUseMock);
    }
  }

  async function fetch(force) {
    const enhancedDesktopExpId = experimentationConfig.find(({ ks }) => ks === 'enhancedDesktopNavEnabled').treatment;
    const enhancedMobileExpId = experimentationConfig.find(({ ks }) => ks === 'enhancedMobileNavEnabled').treatment;
    const enhancedDesktopNavEnabled = viewType.value === viewTypes.desktop && experimentIds.value.includes(enhancedDesktopExpId);

    if (enhancedDesktopNavEnabled && !(store.state[NAV_KILLSWITCH_STORE].killswitches.enhancedDesktopNavEnabled === false)) {
      await Promise.all([
        _fetch(force),
        _fetch(force, viewTypes.mobile, [enhancedMobileExpId], true),
      ]);
    } else {
      await _fetch(force);
    }
  }

  /**
   * @returns {MobileFlyoutElement[]}
   */
  function getTopMenuCategories() {
    const enhancedNav = experiments.value.enhancedMobileNavEnabled || experiments.value.enhancedDesktopNavEnabled;

    return enhancedNav
      ? getGroupsTopCategories()
      : getMobileTopCategories();
  }

  function getMyAccountLinks() {
    return store.getters[`headerStore/${GET_MYACCOUNT_LINKS}`];
  }

  function getAccountText(type) {
    return type === 'enhanced' && isSignedIn.value ? `${username.value}'s account` : 'My Account';
  }

  function getHeaderData() {
    return store.getters[`headerStore/${GET_HEADER_DATA}`];
  }

  /**
   * @param {string} id
   * @returns {MobileFlyoutElement}
   */
  function getSelectedMediaCategory({ id, type = null }) {
    if (id === 'SITE_MYACCOUNT_MENU') {
      const accountLinks = getMyAccountLinks();
      const filterByText = (isMcom ? 'My Account' : 'Account Overview');

      return {
        id: 'SITE_MYACCOUNT_MENU',
        text: getAccountText(type),
        children: accountLinks,
        // enhanced requirements:
        // 1. removing my account from list
        // 2. capturing my account url for "view" cta label
        ...(type === 'enhanced' ? {
          url: accountLinks.find((item) => item.text === filterByText)?.url || '/myaccount/home',
        } : {}),
      };
    }

    const headerData = getHeaderData();
    const selectedCategory = headerData.media[id];

    return {
      id,
      text: 'My Account',
      children: selectedCategory.items[0]?.children,
    };
  }

  function addHardcodedLinkMcom(id, result) {
    const headerData = getMenuData();
    const node = headerData[id];

    if (node?.url) {
      const { isCatSplash } = node;
      let beginning;

      if (isCatSplash) {
        beginning = 'Featured For';
      } else {
        beginning = node.text.startsWith('All ') ? 'See' : 'See All';
      }

      const parent = {
        id,
        url: node.url,
        text: `${beginning} ${node.text}`,
        isParentCategory: true,
      };

      return [parent, ...result];
    }

    return result;
  }

  function addHardcodedLinkBcom(id, result) {
    const headerData = getMenuData();
    const node = headerData[id];

    if (node?.url) {
      const parent = {
        id,
        url: node.url,
        text: BcomFobTitle[id] || 'See All',
        isParentCategory: true,
      };
      return [parent, ...result];
    }

    return result;
  }

  /**
   * @param {string} id
   * @returns {MobileFlyoutElement}
   */
  function getFlyout(id) {
    const headerData = getMenuData();
    const flyout = headerData[id];

    return {
      ...flyout,
      children: isMcom
        ? addHardcodedLinkMcom(id, flyout?.children || [])
        : addHardcodedLinkBcom(id, flyout?.children || []),
    };
  }

  /**
   * @param {string} id
   * @returns {String}
   */
  function getParentFlyoutId(id) {
    const headerData = getMenuData();
    return headerData[id]?.parentId;
  }

  /**
   * @returns {string[]}
   */
  function getAllCategoryIds() {
    const headerData = getMenuData();

    return Object.keys(headerData).reduce((acc, id) => {
      if (headerData[id].children?.length) {
        acc.push(id);
      }

      return acc;
    }, []);
  }

  /*
   * @param {string} currentTitle
   * @returns {string}
   * Returns the actual active category title, considering the stack of categories.
   * If the stack only contains the root category, it returns the current title.
   * Otherwise, it returns the title based on the link text of the previous category children.
   */
  function getActualActiveCategoryTitle(currentTitle) {
    const stackLength = stack.value.length;

    if (stackLength < 2) {
      return currentTitle;
    }

    const prevCategory = getFlyout(stack.value[stackLength - 2].id);
    const currentCategoryId = lastStackItem().id;

    return prevCategory?.children.find(({ id }) => id === currentCategoryId)?.text || currentTitle;
  }

  return {
    stack, // exposing stack for tests only
    getMenuData,
    getFlyouts,
    getMobileTopCategories,
    getGroupsTopCategories,
    getIsAemMenu,
    getDataSource,
    getTopMenuCategories,
    getSelectedMediaCategory,
    getActualActiveCategoryTitle,
    getFlyout,
    getParentFlyoutId,
    getAllCategoryIds,
    fetch,
    processFetchedData,
  };
}

export default {};
