<template>
  <BaseHeader />
  <BaseFooterLazyHydrated />
</template>

<script setup>
/* istanbul ignore file */
import BaseHeader from './components/BaseHeader.common';
import BaseFooterLazyHydrated from './components/BaseFooterLazyHydrated.common';
import { useProviders } from './composables/useProviders';

useProviders();
</script>
