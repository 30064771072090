<template>
  <MobileNav
    v-show="mounted"
    @close="closeNav"
  >
    <LazilyHydratedAsyncMenu v-if="headerData" />
  </MobileNav>
</template>

<script setup>
/* istanbul ignore file */
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useEventBus, useMounted } from '@vueuse/core';

import { hydrateWhenTriggered } from '../../modules/lazy-hydration';
import { GET_HEADER_DATA } from '../../types/getters';
import { OVERLAY, events } from '../../constants/eventBus';
import { onBrowserIdle } from '../../events';
import { useMobileNav } from '../../composables/useMobileNav';
import MobileNav from './MobileNav.common';

const store = useStore();
const mounted = useMounted();
const { isNavSSR } = store.state.envProps;
const { closeNav, isMenuOpened } = useMobileNav();
const isBrowserIdle = ref(false);
const isHydrationTriggered = computed(() => isMenuOpened.value || isBrowserIdle.value || !isNavSSR);

const headerData = computed(() => store.getters[`headerStore/${GET_HEADER_DATA}`]);
const LazilyHydratedAsyncMenu = hydrateWhenTriggered(
  () => import(/* webpackChunkName: "Nav_MobileNavContent" */ './MobileNavContent.common'),
  isHydrationTriggered,
);

onBrowserIdle(() => {
  isBrowserIdle.value = true;
}, { timeout: 300, availableIdleTime: 5 });

const bus = useEventBus(OVERLAY);
bus.on((event) => {
  if (event === events[OVERLAY].CLICK && isMenuOpened.value) closeNav();
});
</script>
