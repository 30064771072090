/* eslint-disable no-shadow */
import {
  GET_HEADER_DATA,
  GET_MYACCOUNT_LINKS,
  GET_DEALS_DATA,
  GET_SERVICE_LINKS,
  GET_SEARCH_MODAL_STATE,
  GET_ATTRS,
} from '../../types/getters';
import {
  SET_HEADER_DATA,
  SET_SEARCH_MODAL_STATE,
  SET_SEARCH_VALUE,
  SET_SEARCH_BAR_ANIMATE,
  SET_SKIP_CONTENT_OFFSET,
  SET_ATTRS,
  SET_FETCH_ERROR,
} from '../../types/mutations';
import { APPLY_SEARCH_TERM } from '../../types/actions';
import { viewTypes } from '../../constants';
import getBcomServiceLinks from '../../constants/ServiceLinks';
import { addSignedInState } from './helpers';

export function state() {
  return {
    data: {
      [viewTypes.desktop]: null,
      [viewTypes.mobile]: null,
    },
    isFetchError: false,
    attrs: {},
    isSearchModalActive: false,
    isSearchBarAnimated: false,
    permanentSigninTooltip: false,
    searchValue: '',
    skipToContentOffset: 0,
  };
}
export const getters = {
  [GET_HEADER_DATA](state, _, rootState) {
    const { viewType } = rootState.navViewTypeStore;
    return state.data[viewType];
  },
  [GET_SERVICE_LINKS](state, getters, rootState) {
    const { [GET_HEADER_DATA]: data } = getters;

    if (rootState.envProps.isMcom) {
      return data.media?.SITE_SERVICE_LINKS?.items?.[0]?.children || [];
    }

    return getBcomServiceLinks(rootState);
  },
  [GET_MYACCOUNT_LINKS](state, getters, rootState) {
    const { [GET_HEADER_DATA]: data } = getters;

    return addSignedInState(data?.media?.SITE_MYACCOUNT_MENU?.items?.[0]?.children || [], rootState);
  },
  [GET_DEALS_DATA](_, getters) {
    const { [GET_HEADER_DATA]: data } = getters;

    return data?.skinnyDealsBanner;
  },
  [GET_SEARCH_MODAL_STATE](state) {
    return state.isSearchModalActive;
  },
  [GET_ATTRS](state) {
    return state.attrs;
  },
};

export const mutations = {
  [SET_HEADER_DATA](state, { viewType, data }) {
    const currentData = state.data[viewType] || {};

    state.data[viewType] = {
      ...currentData,
      ...data,
      menu: data.mobileMenu || data.menu || currentData.menu,
      desktopMenu: data.desktopMenu || currentData.desktopMenu,
      mobileMenu: null,
    };
  },
  [SET_SEARCH_MODAL_STATE](state, payload) {
    state.isSearchModalActive = payload;
  },
  [SET_ATTRS](state, payload) {
    state.attrs = payload;
  },
  [SET_SEARCH_VALUE](state, payload) {
    state.searchValue = payload;
  },
  [SET_SEARCH_BAR_ANIMATE](state, payload) {
    state.isSearchBarAnimated = payload;
  },
  [SET_SKIP_CONTENT_OFFSET](state, payload) {
    state.skipToContentOffset = payload;
  },
  [SET_FETCH_ERROR](state, flag) {
    state.isFetchError = flag;
  },
};

export const actions = {
  [APPLY_SEARCH_TERM]({ rootState, commit }) {
    const committedSearchTerm = rootState?.pageData?.meta?.context?.originalSearchTerm || rootState?.pageData?.meta?.context?.searchTerm;

    if (committedSearchTerm) {
      commit(SET_SEARCH_VALUE, committedSearchTerm);
    }
  },
};
