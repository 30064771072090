import { slideoutStore } from '@atomic-ui/slideout';
import { internationalContextStore } from '@common-vue/international-context/src/store';
import modalOverlayStore from '@common-vue/atomic-ui/src/store_modules/modalOverlay/store';
import { wishlistStore } from '@common-vue/product-thumbnail';
import { recommendationStore } from '@common-vue/pros-collection';

import headerStore from './headerStore';
import footerStore from './footerStore';
import viewTypeStore from './viewTypeStore';
import experimentation from './experimentation';
import killswitchStore from './killswitchStore';
import loyaltyStore from './loyaltyStore';
import preferredStore from './preferredStore';
import searchSuggestionsStore from './searchSuggestionsStore';
import quickBag from './quickBag';
import overlay from './overlay';
import user from './user';
import registryStore from './registryStore';
import sharedStore from './sharedStore';

import {
  NAV_EXPERMENTATION_STORE,
  NAV_KILLSWITCH_STORE,
  NAV_PREFERRED_STORE,
  NAV_VIEW_TYPE_STORE,
  NAV_SEARCH_SUGGESTIONS_STORE,
  NAV_LOYALTY_STORE,
  NAV_QUICK_BAG_STORE,
  NAV_OVERLAY_STORE,
  NAV_USER_STORE,
  NAV_REGISTRY_STORE,
  NAV_SHARED_STORE,
} from '../types/names';

export const ownModules = {
  headerStore,
  footerStore,
  [NAV_VIEW_TYPE_STORE]: viewTypeStore,
  [NAV_EXPERMENTATION_STORE]: experimentation,
  [NAV_KILLSWITCH_STORE]: killswitchStore,
  [NAV_LOYALTY_STORE]: loyaltyStore,
  [NAV_PREFERRED_STORE]: preferredStore,
  [NAV_SEARCH_SUGGESTIONS_STORE]: searchSuggestionsStore,
  [NAV_QUICK_BAG_STORE]: quickBag,
  [NAV_OVERLAY_STORE]: overlay,
  [NAV_USER_STORE]: user,
  [NAV_REGISTRY_STORE]: registryStore,
  [NAV_SHARED_STORE]: sharedStore,
};

export const dependencyModules = {
  slideoutStore,
  modalOverlayStore,
  internationalContextStore,
  wishlistStore,
  recommendationStore,
};

const modules = {
  ...ownModules,
  ...dependencyModules,
};

export default modules;
