import { ref } from 'vue';
import { useStore } from 'vuex';
import useProvideFactory from '../dumb/useProvideFactory';

export const ACCOUNT_SLIDE_OUT_REPOSITORY = Symbol('ACCOUNT_SLIDEOUT_REPOSITORY');

export function getAccountSlideOutSharedState() {
  return ref('');
}

export function useAccountSlideOutProvide(useAsInject) {
  return useProvideFactory({
    key: ACCOUNT_SLIDE_OUT_REPOSITORY,
    factory: getAccountSlideOutSharedState,
    useAsInject,
  });
}

export default function useInternationalSlideout() {
  const slideoutId = useAccountSlideOutProvide(true);
  const store = useStore();

  function init(id) {
    slideoutId.value = id;
  }

  function toggleSlideout() {
    store.commit('slideoutStore/TOGGLE', slideoutId.value);
  }

  function openAccountSlideout() {
    toggleSlideout();
  }

  return {
    slideoutId,
    init,
    openAccountSlideout,
    toggleSlideout,
  };
}
