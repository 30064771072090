<template>
  <div
    v-if="rviProducts.length"
    class="carousel"
    :class="{ 'with-history': searchHistory.length }"
  >
    <ProsCollection
      data-testid="product-carousel"
      :panel-config="panelConfig"
      :recommendation-config="recommendationConfig"
    />
  </div>
</template>

<script setup>
/* istanbul ignore file */
import { onMounted, ref } from 'vue';
import { ProsCollection } from '@common-vue/pros-collection';
import prosCollectionComponentType from '@common-vue/pros-collection/src/utils/prosCollectionComponentType';

import useSearchHistory from '../../composables/dumb/useSearchHistory';

const { searchHistory } = useSearchHistory();
const rviProducts = ref('');

const panelConfig = {
  componentType: prosCollectionComponentType.RVI_CAROUSEL,
  panelType: 'rvi',
  numberOfItems: { large: 5, medium: 5.45, small: 2.45 },
  useWishList: true,
  properties: { isProsOpenInNewTabEnabled: true },
};
const recommendationConfig = {
  zone: 'HNF',
};

onMounted(() => {
  rviProducts.value = localStorage.getItem('recentlyViewedItems') || '';
});
</script>

<style scoped lang="scss" brand="bcom">
@import '../../scss/mixins.bcom.scss';

.carousel {
  display: block;
  padding: 0;
  @include margin-top-m;
  @include margin-left-z;
  @include margin-right-z;
  @include margin-bottom-z;
  &.with-history {
    @include margin-top-l;
  }
}

:deep(h3) {
  @include header(h4);
  text-transform: uppercase;
}

@include breakpoint(large up) {
  .carousel {
    max-width: 584px;
    @include margin-top-l;
    @include margin-left-m;
    &.with-history {
      @include margin-top-xl;
    }
  }
}

@include breakpoint(mlarge up) {
  .carousel {
    max-width: 644px;
  }
}
</style>
